import siteMetadata from 'data/siteMetadata'
import Script from 'next/script'

const UmamiScript = () => {
  return (
    <>
      <Script
        async
        defer
        data-cache="true"
        data-website-id={siteMetadata.analytics.umamiWebsiteId}
        src="https://tanjiro.akashrajpurohit.com/monjiro.js"
      />
    </>
  )
}

export default UmamiScript
