/* eslint-disable @typescript-eslint/no-explicit-any */
import siteMetadata from 'data/siteMetadata'
import { isProd } from 'lib/utils/constants'
import Plausible from './Plausible'
import SimpleAnalytics from './SimpleAnalytics'
import Umami from './Umami'

declare global {
  interface Window {
    plausible?: (...args: any[]) => void
    sa_event?: (...args: any[]) => void
    umami?: {
      track: (event_name: string, event_value: Record<any, any>) => void
    }
  }
}

const Analytics = () => {
  return (
    <>
      {isProd && siteMetadata.analytics.plausibleDataDomain && <Plausible />}
      {isProd && siteMetadata.analytics.simpleAnalytics && <SimpleAnalytics />}
      {isProd && siteMetadata.analytics.umamiWebsiteId && <Umami />}
    </>
  )
}

export default Analytics
