type SocialPlatform = {
  id: string
  name: string
  url: string
  invertDark: boolean
}

export const social: SocialPlatform[] = [
  {
    id: 'github',
    name: 'GitHub',
    url: 'https://github.com/AkashRajpurohit/',
    invertDark: true,
  },
  {
    id: 'linkedin',
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/in/AkashRajpurohit',
    invertDark: false,
  },
  {
    id: 'twitter',
    name: 'Twitter',
    url: 'https://twitter.com/AkashWhoCodes',
    invertDark: false,
  },
  {
    id: 'instagram',
    name: 'Instagram',
    url: 'https://www.instagram.com/akashwho.codes/',
    invertDark: false,
  },
  {
    id: 'devto',
    name: 'Dev.to',
    url: 'https://dev.to/akashrajpurohit',
    invertDark: true,
  },
  {
    id: 'producthunt',
    name: 'ProductHunt',
    url: 'https://www.producthunt.com/@akash_rajpurohit/made',
    invertDark: false,
  },
]
