import { trackEvent } from 'lib/utils/analytics'
import DevTo from './devto.svg'
import Facebook from './facebook.svg'
import Github from './github.svg'
import Instagram from './instagram.svg'
import Linkedin from './linkedin.svg'
import Mail from './mail.svg'
import ProductHunt from './producthunt.svg'
import Rss from './rss.svg'
import Twitter from './twitter.svg'
import Youtube from './youtube.svg'
import Whatsapp from './whatsapp.svg'
import Telegram from './telegram.svg'

// Icons taken from: https://simpleicons.org/

const components = {
  mail: Mail,
  github: Github,
  facebook: Facebook,
  youtube: Youtube,
  linkedin: Linkedin,
  twitter: Twitter,
  rss: Rss,
  producthunt: ProductHunt,
  instagram: Instagram,
  devto: DevTo,
  whatsapp: Whatsapp,
  telegram: Telegram,
}

const SocialIcon = ({ kind, href, size = 8 }) => {
  if (!href || (kind === 'mail' && !/^mailto:\w+([.-]?\w+)@\w+([.-]?\w+)(.\w{2,3})+$/.test(href)))
    return null

  const SocialSvg = components[kind]
  const onSocialLinkClick = () => {
    trackEvent({ name: 'Social Link Click', data: { social: kind, link: href } })
  }

  return (
    <a
      className="text-sm text-gray-500 transition hover:text-gray-600"
      target="_blank"
      rel="noopener noreferrer"
      href={href}
      onClick={onSocialLinkClick}
    >
      <span className="sr-only">{kind}</span>
      <SocialSvg
        className={`fill-current text-gray-700 dark:text-gray-200 hover:text-primary-500 dark:hover:text-primary-400 h-${size} w-${size}`}
      />
    </a>
  )
}

export default SocialIcon
