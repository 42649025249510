import { classNames } from 'lib/utils/classnames'
import siteMetadata from 'data/siteMetadata'
import {
  Action,
  ActionImpl,
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarProvider,
  KBarResults,
  KBarSearch,
  Priority,
  useMatches,
} from 'kbar'
import { trackEvent } from 'lib/utils/analytics'
import { social } from 'lib/utils/social'
import { useTheme } from 'next-themes'
import { useRouter } from 'next/router'
import React, { forwardRef, FunctionComponent } from 'react'
import {
  Code,
  Copy,
  Edit2,
  Home,
  Mail,
  Moon,
  Sun,
  Tag,
  User,
  Zap,
  Shield,
  FileText,
  Tool,
  Twitter,
} from 'react-feather'
import toast from 'react-hot-toast'
import SocialIcon from './icons/social-icons'

const { pages } = siteMetadata

type RenderParams<T = ActionImpl | string> = {
  item: T
  active: boolean
}

const addTrackingToActions = (action: Action) => {
  const obj = { ...action }

  if (action.perform) {
    obj.perform = async (...args) => {
      await action.perform(...args)

      trackEvent({ name: 'kbar-click', data: { name: action.name || action.id } })
    }
  }

  return obj
}

const RenderResults: FunctionComponent = () => {
  const { results } = useMatches()

  if (results && results.length === 0) {
    return (
      <div className="w-full flex justify-center items-center flex-col h-72">
        <div className="text-center px-28">
          <p className="font-semibold text-lg">No results found.</p>
          <p>We can’t find anything with that term at the moment, try searching something else.</p>
        </div>
      </div>
    )
  }

  return (
    <KBarResults
      items={results}
      onRender={({ item, active }: RenderParams) =>
        typeof item === 'string' ? (
          <div className="px-4 py-2 text-xs text-gray-500 dark:text-gray-400 uppercase font-semibold">
            {item}
          </div>
        ) : (
          <ResultItem item={item} active={active} />
        )
      }
    />
  )
}

const ResultItem = forwardRef(
  ({ item, active }: RenderParams<ActionImpl>, ref: React.Ref<HTMLDivElement>) => {
    return (
      <div
        ref={ref}
        className={classNames(
          'flex items-center justify-center cursor-pointer py-2 px-4 transition-colors',
          active ? 'bg-gray-100 dark:bg-gray-800 border-l-2 border-l-primary-500' : 'bg-transparent'
        )}
      >
        <div className="flex gap-3 min-h-fit items-center w-full text-base">
          {item.icon && <div className="mr-2 flex">{item.icon}</div>}
          <div className="flex items-center justify-between p-2 w-full flex-1">
            <div className="flex flex-col">
              <div className="flex flex-row">
                {(item.ancestors || []).map((ancestor: ActionImpl) => (
                  <React.Fragment key={ancestor.id}>
                    <span className="mr-2 opacity-50">{ancestor.name}</span>
                    <span className="mr-2">&rsaquo;</span>
                  </React.Fragment>
                ))}
                <span className="flex-auto text-base line-clamp-1 capitalize">{item.name}</span>
              </div>
              {item.subtitle && <span className="text-xs">{item.subtitle}</span>}
            </div>
          </div>
          <div className="flex gap-2">
            {(item.shortcut || []).map((shortcut) => (
              <div key={shortcut}>
                <kbd className="flex px-4 py-1 items-center justify-center rounded-md bg-gray-200 dark:bg-gray-700">
                  {shortcut}
                </kbd>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
)

ResultItem.displayName = 'ResultItem'

const CommandBar: FunctionComponent = ({ children }): JSX.Element => {
  const { push } = useRouter()
  const { setTheme } = useTheme()

  const actions: Action[] = [
    {
      id: 'home',
      name: 'Home',
      subtitle: 'Home sweet home',
      shortcut: ['g', 'h'],
      keywords: 'go-home',
      section: 'Go To',
      priority: Priority.HIGH,
      icon: <Home size={20} />,
      perform: async () => push(pages.home),
    },
    {
      id: 'blog',
      name: 'Blogs',
      subtitle: 'My thoughts around software engineering.',
      shortcut: ['g', 'b'],
      keywords: 'go-blog',
      section: 'Go To',
      priority: Priority.HIGH,
      icon: <Edit2 size={20} />,
      perform: async () => push(pages.blogs),
    },
    {
      id: 'projects',
      name: 'Projects',
      subtitle: 'Some of my fun projects I do over the weekends.',
      shortcut: ['g', 'p'],
      keywords: 'go-projects',
      section: 'Go To',
      priority: Priority.HIGH,
      icon: <Zap size={20} />,
      perform: async () => push(pages.projects),
    },
    {
      id: 'snippets',
      name: 'Snippets',
      subtitle: 'Code snippets I use in projects.',
      shortcut: ['g', 's'],
      keywords: 'go-snippets',
      section: 'Go To',
      priority: Priority.HIGH,
      icon: <Code size={20} />,
      perform: async () => push(pages.snippets),
    },
    {
      id: 'tags',
      name: 'Tags',
      subtitle: 'Search blogs by tags.',
      shortcut: ['g', 't'],
      keywords: 'go-tags',
      section: 'Go To',
      priority: Priority.HIGH,
      icon: <Tag size={20} />,
      perform: async () => push(pages.tags),
    },
    {
      id: 'about',
      name: 'About',
      subtitle: 'Some details about me.',
      shortcut: ['g', 'a'],
      keywords: 'go-about',
      section: 'Go To',
      priority: Priority.HIGH,
      icon: <User size={20} />,
      perform: async () => push(pages.about),
    },
    {
      id: 'resume',
      name: 'Resume',
      subtitle: 'Read about my journey of life so far.',
      shortcut: ['g', 'r'],
      keywords: 'go-resume',
      section: 'Go To',
      priority: Priority.HIGH,
      icon: <FileText size={20} />,
      perform: async () => push(pages.resume),
    },
    {
      id: 'uses',
      name: 'Uses',
      subtitle: 'The products and types of equipment I use.',
      shortcut: ['g', 'u'],
      keywords: 'go-uses',
      section: 'Go To',
      priority: Priority.HIGH,
      icon: <Tool size={20} />,
      perform: async () => push(pages.uses),
    },
    {
      id: 'newsletter',
      name: 'Newsletter',
      subtitle: 'Subscribe to our newsletter.',
      shortcut: ['g', 'n'],
      keywords: 'go-newsletter',
      section: 'Go To',
      priority: Priority.HIGH,
      icon: <Mail size={20} />,
      perform: async () => push(pages.newsletter),
    },
    {
      id: 'tweets',
      name: 'Tweets',
      subtitle: 'Tweets that I loved reading and saved here as bookmarks.',
      keywords: 'go-tweets',
      section: 'Go To',
      priority: Priority.HIGH,
      icon: <Twitter size={20} />,
      perform: async () => push(pages.tweets),
    },
    {
      id: 'privacy-policy',
      name: 'Privacy Policy',
      subtitle: 'Curious to see whats going on here?',
      keywords: 'go-privacy-policy',
      section: 'Go To',
      priority: Priority.HIGH,
      icon: <Shield size={20} />,
      perform: async () => push(pages.privacyPolicy),
    },
    {
      id: 'theme',
      name: 'Change theme',
      shortcut: ['t'],
      keywords: 'theme',
      icon: <Moon size={20} />,
      priority: Priority.HIGH,
      section: 'Utilities',
    },
    {
      id: 'copy-link',
      name: 'Copy URL',
      shortcut: ['u'],
      keywords: 'copy-url',
      icon: <Copy size={20} />,
      priority: Priority.HIGH,
      section: 'Utilities',
      perform: async () => {
        const url = window.location.href
        await navigator.clipboard.writeText(url)
        toast('Copied the url to your clipboard')
      },
    },
    {
      id: 'email',
      name: 'Send Email',
      shortcut: ['e'],
      keywords: 'send-email',
      icon: <Mail size={20} />,
      priority: Priority.HIGH,
      section: 'Utilities',
      perform: async () => {
        const url = `mailto:${siteMetadata.email}`
        window.open(url, '_blank')
      },
    },
    {
      id: 'light-mode',
      name: 'Light',
      shortcut: ['l'],
      keywords: 'light',
      icon: <Sun size={20} />,
      parent: 'theme',
      priority: Priority.HIGH,
      perform: () => setTheme('light'),
    },
    {
      id: 'dark-mode',
      name: 'Dark',
      shortcut: ['d'],
      keywords: 'dark',
      icon: <Moon size={20} />,
      parent: 'theme',
      priority: Priority.HIGH,
      perform: () => setTheme('dark'),
    },
  ]

  const socialActions: Action[] = social.map(({ id, name, url }) => ({
    id,
    name,
    keywords: id,
    icon: <SocialIcon kind={id} href={url} size={6} />,
    section: 'Follow',
    priority: Priority.NORMAL,
    perform: () => window.open(url, '_blank'),
  }))

  const allActions = [...actions, ...socialActions].map(addTrackingToActions)

  return (
    <KBarProvider actions={allActions}>
      <KBarPortal>
        <KBarPositioner className="z-30 bg-white/80 backdrop-blur-sm dark:bg-gray-800/80">
          <KBarAnimator className="mx-auto w-full max-w-xl overflow-hidden rounded-xl bg-white drop-shadow-2xl dark:bg-gray-900">
            <KBarSearch className="font-md w-full border-b border-gray-100 bg-transparent py-3 px-4 font-normal text-gray-900 outline-none dark:border-gray-900 dark:text-white" />
            <RenderResults />
          </KBarAnimator>
        </KBarPositioner>
      </KBarPortal>
      {children}
    </KBarProvider>
  )
}

export default CommandBar
