import AOS from 'aos'
import 'aos/dist/aos.css'
import Analytics from 'components/analytics'
import CommandBar from 'components/CommandBar'
import 'css/prism.css'
import 'css/tailwind.css'
import { isDesktop } from 'lib/utils/constants'
import { ThemeProvider } from 'next-themes'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast'

export default function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    AOS.init({
      easing: 'ease',
      once: true,
      offset: 40,
      duration: 1000,
    })
  }, [])

  useEffect(() => {
    if (isDesktop()) {
      toast('You can use ⌘K to open the menu', { duration: 6000 })
    }
  }, [])

  return (
    <ThemeProvider attribute="class">
      <Head>
        <meta content="width=device-width, initial-scale=1" name="viewport" />
      </Head>
      <Analytics />
      <CommandBar>
        <Component {...pageProps} />
      </CommandBar>
      <Toaster
        containerClassName="print:hidden"
        toastOptions={{
          duration: 5000,
          position: 'bottom-right',
        }}
      />
    </ThemeProvider>
  )
}
